<script>
  import { fade } from 'svelte/transition';
  import NavLink from './NavLink.svelte';
  
  export let show;
  export let sub_pages = [];
  export let request_store_key;

  function focus() {
    show = true;
  }

  function blur() {
    show = false;
  }

</script>

<div
  on:mouseover={focus}
  on:focus={focus}
  on:mouseout={blur}
  on:blur={blur}
  transition:fade
  class="dropdown"
  class:show
>
  <div class="arrow-down"></div>
  <div class="shadow p-3 mb-0 rounded box-body">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <ul class="p-0">
            {#each sub_pages as nav_item}
              <li class="item">
                <NavLink {nav_item} {request_store_key}/>
              </li>
            {/each}
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .box-body {
    margin: 29px auto;
    border: 1px solid #dee2e6;
    margin-bottom: 0px;
    background-color: #fff;
    z-index: -1;
    ul {
      margin-bottom: 0;
    }
  }
  .arrow-down {
    position: absolute;
    left: 52%;
    transform: translateX(-50%);
    --t: 1px;
    --c: #dee2e6;
    width: 30px;
    height: 30px;
    border: var(--t) solid transparent;
    border-bottom-color: var(--c);
    background: linear-gradient(
          to bottom left,
          transparent 49.5%,
          var(--c) 50% calc(50% + var(--t)),
          white calc(50% + var(--t) + 1px)
        )
        right,
      linear-gradient(
          to bottom right,
          transparent 49.5%,
          var(--c) 50% calc(50% + var(--t)),
          white calc(50% + var(--t) + 1px)
        )
        left;
    background-size: 50% 100%;
    background-origin: border-box;
    background-repeat: no-repeat;
    position: absolute;
    @media only screen and (min-width: 1200px) and (max-width: 1439px) {
      left: 50%;
    }
  }

  .arrow-down:after {
    content: '';
    width: 26px;
    border: 1px solid #fff;
    position: absolute;
    top: 28px;
    z-index: 999999;
    left: 1px;
    border-radius: 7px;
  }
  .dropdown {
    display: none;
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;
    z-index: 1000;
    width: max-content;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .show {
    display: block;
  }

  .item {
    color: #101820;
    font-weight: 400;
    letter-spacing: 1px;
    list-style: none;
    :global(a) {
      padding: 8px 20px;
      font-size: 16px;
      text-align: left;
    }

    &:last-child {
      border-bottom: none;
    }
  }
</style>
