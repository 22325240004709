<script>
  import SearchInput from './SearchInput.svelte';
  export let show;
  export let request_store_key;
  
  function focus() {
    show = true;
  }

  function blur() {
    show = false;
  }

  let searchInput;
</script>

<div
  on:mouseover={focus}
  on:focus={focus}
  on:mouseout={blur}
  on:blur={blur}
  class="dropdown"
  class:show
>
  <div class="arrow-down show"></div>
  <div class="shadow px-3 py-5 mb-5 bg-body mx box1">
    <div class="row">
      <div class="col-10"><SearchInput {request_store_key} bind:this={searchInput} {show} /></div>
      <div class="col-2">
        <button on:click={() => searchInput.submit()} class="bi-search mx-auto d-inline-block" />
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .bg-body {
    background-color: #fff !important;
  }
  .bi-search {
    font-size: 28px;
    color: var(--color-accent-primary-text);
    cursor: pointer;
    border: 0;
  }


  .box1 {
    margin: 42px auto;
    border: 1px solid #dee2e6;
  }
  .arrow-down {
    --t: 1px;
    --c: #f1f1f1;
    width: 30px;
    height: 30px;
    border: var(--t) solid transparent;
    border-bottom-color: var(--c);
    background: linear-gradient(
          to bottom left,
          transparent 49.5%,
          var(--c) 50% calc(50% + var(--t)),
          white calc(50% + var(--t) + 1px)
        )
        right,
      linear-gradient(
          to bottom right,
          transparent 49.5%,
          var(--c) 50% calc(50% + var(--t)),
          white calc(50% + var(--t) + 1px)
        )
        left;
    background-size: 50% 100%;
    background-origin: border-box;
    background-repeat: no-repeat;
    position: absolute;
    top: 12px;
    left: 280px;
  }

  .arrow-down:after {
    content: '';
    width: 26px;
    border: 1px solid #fff;
    position: absolute;
    top: 28px;
    z-index: 999999;
    left: 1px;
    border-radius: 7px;
  }
  .dropdown {
    display: none;
    position: absolute;
    width: 400px;
    margin: 0;
    padding: 0;
    background-color: transparent;
    z-index: 1000;
    left: -250px;
    top: 30px;
  }

  .show {
    display: block;
  }
</style>
