<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { type Alternate } from "~/metadata";
  import { ReverseRouter } from "~/reverse-router";

  export let request_store_key: StoresKey;
  export let supported_languages: any;

  const { locale, reverse_router, alternate } = getStoresContext(request_store_key);
  const language_list = {
    fi: { name: 'Suomi', code: 'FI', key: 'fi-FI' },
    en: { name: 'English', code: 'EN', key: 'en-US' }
  };
  
  $: inactive_language = $locale == 'fi-FI' ? language_list['en'] : language_list['fi'];

  function hrefFor (
    locale: string, 
    alternate: readonly Alternate[], 
    reverse_router: Readonly<ReverseRouter>
  ) {
    const alt = alternate.find(x => x.locale == locale);
    return alt ? alt.href : reverse_router.findPurposeRoute('home page', locale);
  }

</script>

{#if $locale && supported_languages.length }
  <div class="language-dropdown">
    <a  href={hrefFor(inactive_language.key, $alternate, $reverse_router)}>
      <span class="active-language-code">
        {inactive_language.code}
      </span>
      {inactive_language.name}
    </a>
  </div>
{/if}

<style lang="scss">
  .language-dropdown a {
    color: #000;
  }

  .active-language-code {
    border: 2.5px solid #787878;
    padding: 2px;
    vertical-align: middle;
  }
</style>
