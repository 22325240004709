<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { slide } from "svelte/transition";
                    
  export let request_store_key: StoresKey;
  export let nav_item:any;

  const { site_page, i18nOf, reverse_router } = getStoresContext(request_store_key);
  $:navItem = nav_item
  $: current = $site_page && $site_page.friendlyUrlPath;
  $: isActive = current == nav_item.link;
  function toggle(e) {
    e.stopPropagation();
    isActive = !isActive;
  }
</script>

{#if navItem && navItem.navigationMenuItems.length}
  <div class="link" class:active={isActive}>
    <a href={nav_item.link ? $reverse_router.routePage(nav_item.link) : nav_item.url}>
      {i18nOf(nav_item.name_i18n)}
    </a>
    <button class="link-arrow" on:click={toggle} on:keydown={toggle}>
      {#if isActive}
        <i class="bi-chevron-right"></i>
      {:else}
        <i class="bi-chevron-down"></i>
      {/if}
    </button>
  </div>
  {#if isActive}
    <div transition:slide|local>
      <ul class="ps-0">
        {#each navItem.navigationMenuItems as nav_item_subs}
          <li class="item navlink">
            <a
              class="s-link sub-link"
              class:active={current == nav_item_subs.link}
              target={nav_item_subs.link ? "_self" : "_blank"}
              href={nav_item_subs.link ? $reverse_router.routePage(nav_item_subs.link) : nav_item_subs.url}
              >{nav_item_subs.name_i18n ? i18nOf(nav_item_subs.name_i18n) : nav_item_subs.name}
            </a> 
          </li>
        {/each}
      </ul>
    </div>
  {/if}
{:else}
  <a class="s-link" class:active={isActive} href={nav_item.link ? $reverse_router.routePage(nav_item.link) : nav_item.url}>
    {i18nOf(nav_item.name_i18n)}
  </a>
{/if}

<style lang="scss">
  .s-link {
    color: var(--color-white);
    background-color: var(--color-accent-primary);
    text-align: left;
    padding: 1rem;
    display: block;
  }
  .sub-link {
    background-color: var(--color-accent-primary-dark);
    font-size: 12px;
  }
  .sub-link.active {
    background-color: var(--color-accent-primary-light);
  }
  .active {
    color: var(--color-white);
    font-weight: bold;
  }
  .item {
    list-style: none;
  }
  ul {
    background-color: var(--color-accent-secondary-light);
  }
  // Dropdown collapse
  .link-arrow {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: none;
    background: none;
    width: 50px;
  }
  .link {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-accent-primary);
    i {
      color: var(--color-white);
    }
  }
  a {
    width:100%;
    padding: 1rem;
    color: var(--color-white);
    text-decoration: none;
  }
  .active {
    color: var(--color-white);
    background-color: var(--color-accent-primary-light);
    font-weight: bold;
    a,
    i {
      color: var(--color-white);
      background-color: var(--color-accent-primary-light);
    }
  }
</style>
