<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";

  export let show = false;
  export let request_store_key: StoresKey;
  const { reverse_router, T } = getStoresContext(request_store_key);
  
  $: t = $T;
  $: search_href = $reverse_router.routePurpose("search");

  let value: string = "";
  let searchInput: any;
  $: {
    if (show) {
      searchInput.focus();
    }
  }
  function submit() {
    window.location.href = search_href + "?search=" + value;
  }
</script>

<div class="search__wrapper d-inline">
  <input
    class="searchInput"
    type="text"
    bind:this={searchInput}
    placeholder="{t`search-stores-and-events`}..."
    bind:value
    on:change={submit}
  />
</div>

<style lang="scss">

  .search__wrapper {
    padding-right: 1rem;
    text-align: center;
    position: relative;
    max-width: 300px;
    input {
      width: 100%;
      height: 44px;
      padding: 10px;
      background-color: var(--color-accent-secondary-light);
      border: 0;
      color: #000;
    }
  }
</style>
