<!-- @format -->
<script lang="ts">
  import { onMount } from "svelte";
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import { slide } from "svelte/transition";
  import SearchField from './SearchField.svelte';
  import LanguageSelect from "./LanguageSelect.svelte";
  import SearchInput from '../nav/SearchInput.svelte';
  import VerticalNavigation from '../nav/VerticalNavigation.svelte';
  import NavItem from "../nav/NavItem.svelte";

  export let request_store_key: StoresKey;

  const { locale, nav_menus, reverse_router, site, T } = getStoresContext(request_store_key);

  $: t = $T;
  $: home_page_nav_item = $reverse_router.routePurpose("home page");
  $: supportedLanguages = $site?.supportedLanguages;
  
  $: scrollingPosition = 0;
  $: isOpen = false;
  let isMobileSearchOpen = false;
  let collapse_navigation_button = null;
  let body: HTMLBodyElement;
  $: logo = $site && $site.logo;

  onMount(() => {
    body = document.getElementsByTagName("body")[0];
  });

  $: {
    if (isOpen && body) {
      body.classList.add("overflow-hidden");
    } else if (!isOpen && body) {
      body.classList.remove("overflow-hidden");
    }
  }
  let rest_nav_items:any = [];

  $: navHeader = $nav_menus && $nav_menus.find((nav) => nav.name == "Header");

  $: {
    rest_nav_items = (navHeader?.navigationMenuItems || []).filter(
      (item) => !item.excluded_locales?.some((x) => x == $locale)
    );
  }  
</script>
  
  <svelte:window bind:scrollY={scrollingPosition} />

  <header id="sticky-header" class="fixed-top" class:shadow={scrollingPosition > 50}>
    <div class="container-fluid" id="header">
      <div class="desktop-menu d-none d-xl-block">
        <nav>
          <a class="left-wrapper" href={home_page_nav_item}>
            <img src={logo} alt="Mall of tripla logo" class="d-block" />
          </a>
          <div class="mid-wrapper">
            {#each rest_nav_items as nav_item}
              <NavItem {nav_item} {request_store_key}/>
            {/each}
          </div>
          <div class="right-wrapper search-hours">
            <SearchField {request_store_key}/>
            <LanguageSelect supported_languages={supportedLanguages} {request_store_key}/>
          </div>
        </nav>
      </div>
      <div class="mobile-menu d-xl-none">
        <div class="d-flex justify-content-between align-items-center p-3">
          {#if isOpen}
            <div class="menu">{t`menu`}</div>
          {:else}
            <a class="d-flex align-items-center" href={home_page_nav_item}>
              <img
                src="/theme/malloftripla2024/images/Tripla_logo_mobile.png"
                alt="Mall of tripla logo mobile"
                height="45"
                width="59"
                class="d-xl-none tripla-logo-mobile"
              />
            </a>
          {/if}
          <div
            class:menu-icon-close={isOpen}
            class:menu-icon-burger={!isOpen}
            on:click={() => (isOpen = !isOpen)}
            on:keydown={() => (isOpen = !isOpen)}
            bind:this={collapse_navigation_button}
          >
            {#if isOpen}
              <i class="bi-x"></i>
              <div class="close-text fw-bold">{t`close`}</div>
            {:else}
              <i class="bi-list"></i>
            {/if}
          </div>
        </div>
        {#if isOpen}
          <div class="d-xl-none" transition:slide|local>
            <div class="dropdown">
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div class="d-flex justify-content-between align-items-center search-and-language">
                <LanguageSelect supported_languages={supportedLanguages} {request_store_key}/>
                <div class="navbar-toggler d-xl-none" on:click={() => (isMobileSearchOpen = !isMobileSearchOpen)}>
                  <div class="search-icon"></div>
                  {t`search-menu`}
                </div>
              </div>
              <div class="bg-white" class:d-block={isMobileSearchOpen} class:d-none={!isMobileSearchOpen}>
                <div class="d-flex align-items-center p-4">
                  <div class="col-10"><SearchInput {request_store_key} show={isMobileSearchOpen} /></div>
                  <div class="col-2 text-center">
                    <button
                      class="d-xl-none icon-closed d-inline-block"
                      on:click={() => (isMobileSearchOpen = !isMobileSearchOpen)}
                    />
                  </div>
                </div>
              </div>
              <ul class="navbar-nav">
                {#each rest_nav_items as nav_item}
                  <VerticalNavigation {nav_item} {request_store_key}/>
                {/each}
              </ul>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </header>
  <div class="gap-element"></div>


<style lang="scss">
  .mobile-menu {
    margin: 0 -12px;
    overflow: auto;
    max-height: 100vh;
  }

  header {
    background: #fff;
    font-size: 15px;
    @media screen and (min-width: 992px) {
      font-size: 13px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 1rem;
    }
  }

  .menu-icon-close {
    font-size: 35px;
    text-align: center;
  }

  .menu-icon-burger {
    font-size: 40px;
  }

  .close-text {
    font-size: 14px;
    margin-top: -12px;
  }

  nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .left-wrapper {
    display: flex;
    align-items: center;
  }

  .mid-wrapper {
    display: flex;
  }

  .right-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .search-hours {
    display: flex;
    align-items: center;

    :global(.hours) {
      text-align: right;
    }
  }

  @media screen and (min-width: 992px) {
    nav {
      align-items: center;
      padding: 30px 8px;
    }
    nav img {
      width: 150px;
    }
  }

  @media screen and (min-width: 1200px) {
    nav img {
      width: 190px;
    }
  }

  @media screen and (min-width: 1440px) {
    nav img {
      width: 230px;
    }
  }

  header {
    .tripla-logo-mobile {
      height: 45px;
      width: 59px;
    }
  }

  .menu {
    color: #000;
  }

  .gap-element {
    height: 92px;
    width: auto;
    @media screen and (min-width: 1200px) {
      height: 98px;
    }
  }

  // Mobile menu

  .icon-closed {
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  background-image: url(/theme/malloftripla2024/images/nav_close_white.svg);
  background-color: var(--color-accent-primary);
  height: 35px;
  border-radius: 50%;
  width: 35px;
}

.navbar-nav {
  background-color: var(--color-accent-secondary);
  color: #000;
  flex-direction: column;
}
.search-and-language {
  padding: 10px 20px;
  background-color: var(--color-accent-secondary-light);
}
.search-icon {
  background: url('/theme/malloftripla2024/images/search.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}
.navbar-toggler {
  font-size: 16px;
}
</style>