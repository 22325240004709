<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { StoresKey } from "~/stores";
  import SearchPopover from '../nav/SearchPopover.svelte';

  export let request_store_key: StoresKey;
  
  const { T } = getStoresContext(request_store_key);
  
  $: t = $T;
  let hover = false;

  function focus() {
    hover = true;
  }
  function blur() {
    hover = false;
  }
</script>

<div class="search__wrapper d-lg-flex">
  <div
    on:mouseover={focus}
    on:focus={focus}
    on:mouseout={blur}
    on:blur={blur}
    class="search-icon d-lg-flex d-none mx-2"
  >
    <i class="bi-search mx-2"></i>
    {t`search-menu`}
  </div>
  <SearchPopover {request_store_key} show={hover} />
</div>

<style lang="scss">

  .bi-search {
    font-size: 25px;
  }

  ::placeholder {
    color: #101820;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #101820;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #101820;
  }

  .search-icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    align-items: center;
    cursor: pointer;
  }

  @media screen and (min-width: 992px) {
    .search__wrapper {
      padding-right: 1rem;
      text-align: center;
      position: relative;
      max-width: 300px;
    }
  }
</style>
