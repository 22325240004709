<!-- @format -->
<script lang="ts">
    import { getStoresContext } from "~/stores";
    import type { StoresKey } from "~/stores";
    import DropdownMenu from './DropdownMenu.svelte';

    export let request_store_key: StoresKey;
    export let hover = false;
    export let nav_item: NavigationMenuItem;

    const { site_page, reverse_router, i18nOf } = getStoresContext(request_store_key);

    $: title = i18nOf(nav_item?.name_i18n);
    $: href = nav_item?.link? $reverse_router.routePage(nav_item?.link) : nav_item?.url;

    $: current = $site_page?.friendlyUrlPath;
    $: is_active = current == nav_item?.link;
    $: sub_pages = nav_item?.navigationMenuItems;

</script>


<a class:active={is_active} {href} {title} target={nav_item?.link ? "_self" : "_blank"}> 
    {@html title}
    {#if sub_pages?.length}
        <DropdownMenu {sub_pages} show={hover} {request_store_key}/>
    {/if}
</a>

<style lang="scss">

    .small {
        font-size: 14px;
    }

    a {
        display: inline-block;
        padding: 16px 30px;
        font-weight: normal;
        text-decoration: none;
        color: #000;
        font-weight: 400;
        @media screen and (min-width: 992px) {
        padding: 0.5rem 0.5rem 0;
        text-align: center;
        font-size: 15px;
        }

        @media screen and (min-width: 1200px) {
        font-size: 16px;
        }
        @media screen and (min-width: 1440px) {
        padding-left: 1.5rem;
        font-size: 18px;
        }
    }

    a:hover {
        color: var(--color-black);
        font-weight: bold;
    }
    @media screen and (max-width: 992px) {
        a:hover {
            color: var(--color-accent-secondary);
            background-color: var(--color-black);
        }
    }

    a::before {
        display: block;
        content: attr(title);
        height: 0;
        overflow: hidden;
        visibility: hidden;
        font-weight: bold;
    }

    .active {
        color: var(--color-black);
        font-weight: bold;
        @media screen and (max-width: 992px) {
            color: var(--color-accent-secondary);
            background-color: var(--color-black);
        }
    }
</style>