<script>
  import NavLink from './NavLink.svelte';  

  export let request_store_key;
  export let nav_item = false;

  let hover = false;
  let left;

  function focus(e) {
    var rect = e.target.getBoundingClientRect();
    left =
      rect.left -
      document.getElementById('header').getBoundingClientRect().x +
      e.target.offsetWidth / 3;
    hover = true;
  }
  function blur() {
    hover = false;
  }
  
</script>
  
{#if nav_item?.navigationMenuItems?.length} 
  <div class="sub-menu" on:mouseover={focus} on:focus={focus} on:mouseout={blur} on:blur={blur}>
      <NavLink {nav_item} {hover} {request_store_key}/>
  </div>
{:else}
  <NavLink {nav_item} {hover} {request_store_key}/>
{/if} 

  
<style lang="scss">
  .sub-menu {
    position: relative;
    cursor: pointer;
  }

  .sub-menu::after {
    @media screen and (min-width: 1440px) {
      right: 4px;
    }
  }
</style>